

:root {
  --items: 2;
}



.carousel-item {
    width: calc(100% / var(--items)); /* number of items is 3 */
    padding-left: 15px; 
    padding-right: 15px;
  }
  
  .carousel-item.active {
    display: block;
  }
  
  .carousel-item.active + .carousel-item {
    display: block;
    transform: translateX(100%);
  }
  
  .carousel-item.active + .carousel-item + .carousel-item {
    display: block;
    transform: translateX(200%);
  }
  
  .carousel-item.active + .carousel-item + .carousel-item + .carousel-item {
    display: block;
    transform: translateX(300%);
  }

  .carousel-item.active + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    display: block;
    transform: translateX(400%);
  }

  .carousel-item.active + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item{
    display: block;
    transform: translateX(500%);
  }

  .carousel-item.active + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item + .carousel-item {
    display: block;
    transform: translateX(600%);
  }
  
  /* 
  - if you wish for n items in the carousel just this pattern for i from 1 to n
  - (+ .carousel-item) * i = "+ .carousel-item" i times like above
  .carousel-item.active (+ .carousel-item) * i {
    display: block;
    transform: translateX(i * 100%);
  } */
  
  .carousel-item.active.carousel-item-start + .carousel-item {
    display: block;
    transform: translateX(0);
  }
  
  .carousel-item.active.carousel-item-start + .carousel-item + .carousel-item {
    display: block;
    transform: translateX(100%);
  }
  
  .carousel-item.active.carousel-item-start
    + .carousel-item
    + .carousel-item
    + .carousel-item {
    display: block;
    transform: translateX(200%);
  }
  
  /* 
  - if you wish for n items in the carousel just this pattern for i from 1 to n
  - (+ .carousel-item) * i = "+ .carousel-item" i times like above
  .carousel-item.active.carousel-item-start (+ .carousel-item) * i {
    display: block;
    transform: translateX((i - 1) * 100%);
  } */
  
  .carousel-item.active.carousel-item-end + .carousel-item {
    display: block;
    transform: translateX(200%);
  }
  
  .carousel-item.active.carousel-item-end + .carousel-item + .carousel-item {
    display: block;
    transform: translateX(300%);
  }
  
  /* 
  - if you wish for n items in the carousel just this pattern for i from 1 to n - 1
  - (+ .carousel-item) * i = "+ .carousel-item" i times like above
  .carousel-item.active.carousel-item-end (+ .carousel-item) * i {
    display: block;
    transform: translateX((i + 1) * 100%);
  } */
  
  .carousel-indicators {
    bottom: -20px;
  }

  .carousel {
    padding-bottom: 30px;
  }