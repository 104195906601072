
.thumbnail .caption {
    padding: 0;
    color: inherit;
}



.thumbnail {
    padding: 0;
    border: none;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    box-shadow: none;
    moz-box-shadow: none;
    -webkit-box-shadow: none;
    margin-bottom: 0;
    background: none;
}

.thumbnail .caption {
    padding: 0;
    color: inherit;
}



.thumbnail {
    padding: 0;
    border: none;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
    box-shadow: none;
    moz-box-shadow: none;
    -webkit-box-shadow: none;
    margin-bottom: 0;
    background: none;
}


.thumbnail a {
    display: block;
    text-decoration: none;
    text-align: center;
}

.thumbnail a figure {
    margin: 0;
    float: none;
    margin: 0px 0px 0px 0px;
    position: relative;
    color: #464646;
}

.thumbnail a:hover figure {
    color: #1cbbb4;
}

.thumbnail a figure img {
    width: 100%;
    transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}

.thumbnail a figure .img2 {
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    filter: alpha(opacity=0);
}

.thumbnail a:hover figure .img2 {
    opacity: 1;
    filter: alpha(opacity=100);
}

.thumbnail a:hover figure .img1 {
    opacity: 0;
    filter: alpha(opacity=0);
}



.thumb2 .thumbnail a:hover figure .img2 {
    opacity: 1;
    filter: alpha(opacity=100);
}

.thumb2 .thumbnail a .caption {
    padding-top: 15px;
}

.thumbnail a:hover .caption {}

.thumbnail a .caption .txt1 {
    font-family: 'Raleway';
    font-size: 21px;
    color: #464646;
    font-weight: 500;
    text-decoration: none;
    padding-bottom: 5px;
    position: relative;
    transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}

.thumbnail a:hover .caption .txt1 {
    color: #1cbbb4;
    text-decoration: none;
}

.thumbnail a .caption .txt2 {
    color: #959595;
    padding-bottom: 15px;
}

.thumbnail a .caption .txt3 {
    display: inline-block;
    color: #c2c2c2;
    font-size: 13px;
    padding-right: 15px;
    line-height: 20px;
    background: url(../../assets/images/btn1.png) right center no-repeat;
    transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}

.thumbnail a:hover .caption .txt3 {
    color: #1cbbb4;
    background-image: url(../../assets/images/btn1_hover.png);
}