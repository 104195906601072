/* GOOGLE FONTS */
@import url(https://fonts.googleapis.com/css?family=Raleway:400,100,100italic,200,200italic,300,300italic,400italic,500,500italic,600,600italic,700,700italic,800,800italic,900,900italic);

/* ============================= main layout ====================== */


h1{font-family:'Raleway'; font-weight:700; font-size:36px; color:#323a45; background: none; line-height:1.1; padding:20px 0 20px; margin: 0 0 25px 0; position: relative; border-bottom: 4px solid #046db6;}

h2{font-family:'Raleway'; font-weight:600; font-size:42px; color:#464646; background: none; line-height:1.1; padding:20px 0 20px; margin: 0 0 10px 0; position: relative; text-align: center; text-transform: uppercase;}

h3{font-family:'Raleway'; font-weight:600; font-size:42px; color:#464646; background: none; line-height:1.1; padding:20px 0 20px; margin: 0 0 10px 0; position: relative; text-align: left; text-transform: uppercase;}

h4{font-family:'Raleway'; font-weight:700; font-size:18px; color:#1cbbb4; background: none; line-height:1.1; padding:15px 0 10px; margin: 0 0 0px 0; position: relative; text-align: left; text-transform: uppercase;}

h5{font-family:'Raleway'; font-weight:700; font-size:19px; color:#464646; background: none; line-height:1.1; padding:20px 0 20px; margin: 0 0 0px 0; position: relative; text-align: left;}

.subtitle {
  text-align: center;
  font-size: 18px;
  color: #959595;
  padding-bottom: 15px;
}

/* Global properties ======================================================== */
a[href^="tel:"] {
  color: inherit;
  text-decoration: none;
}

::selection {
  background: #1cbbb4 !important;
  color: #fff
}

::-moz-selection {
  background: #1cbbb4 !important;
  color: #fff
}

a {
  color: #8c8c8c;
  text-decoration: none;
  outline: none
}

a:hover {
  color: #1cbbb4;
  text-decoration: none;
  outline: none
}

.front {
  background: #fff;
  border: 0;
  font: 14px 'Raleway', Arial, Helvetica, sans-serif;
  color: #959595;
  line-height: 1.42857143;
  min-width: 320px;
}

.container {
  position: relative;
  min-width: 320px;
}

#main {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
}

/* Page Animated item Styles */
.animated {
  visibility: hidden;
}

.visible {
  visibility: visible;
}

.img-responsive {
  width: auto \9;
}

.link-list {
  padding: 0;
  margin: 0;
  list-style: none;

  &>li {
    font-size: 15px;
    color: #959595;
    border-bottom: 1px solid #363636;
  }

  &>li a {
    color: #959595;
    text-decoration: none;
    display: block;
    padding: 10px 20px 10px 0px;
    background: url(../images/arr1.png) right center no-repeat;
  }

  &>li a:hover {
    color: #1cbbb4;
    text-decoration: none;
    background: url(../images/arr1_hover.png) right center no-repeat;
  }

}

.btn-default.btn-danger {
  display: inline-block;
  color: #fff;
  border: none;
  font-size: 12px;
  line-height: 20px;
  font-weight: 700;
  padding: 6px 20px;
  text-decoration: none;
  border-radius: 0px;
  -moz-border-radius: 0px;
  -webkit-border-radius: 0px;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
  background: #fb000d;
  position: relative;
  text-align: center;
  box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset;
  moz-box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset;
  -webkit-box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset;
  margin-top: 15px;
  &:hover {
    color: #fff;
    background: #00a99d;
  }
}

/* Page Animated item Styles */
/*.animate__animated {
  visibility: hidden;
}*/

.visible {
  visibility: visible;
}


/*-----btn-----*/
.btn-default.btn0{ display: block; color: #fff; border: none; font-size: 14px; line-height: 20px; font-weight: 700; padding: 15px 10px; text-decoration: none; border-radius:0px; -moz-border-radius:0px; -webkit-border-radius:0px; margin-top: 0px; margin-bottom: 0px; text-transform: uppercase; background: #fb000d; position: relative; text-align: center; width: 100%;box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset; moz-box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset; -webkit-box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset; margin-top: 50px;}
.btn-default.btn0:hover{color: #fff; background: #00a99d;}

.btn-default.btn1{ display: inline-block; color: #fff; border: none; font-size: 12px; line-height: 20px; font-weight: 700; padding: 5px 20px; text-decoration: none; border-radius:0px; -moz-border-radius:0px; -webkit-border-radius:0px; margin-top: 0px; margin-bottom: 0px; text-transform: uppercase; background: #00a99d; position: relative; text-align: center; width: 100%;box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset; moz-box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset; -webkit-box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset;}
.btn-default.btn1:hover{color: #fff; background: #fb000d;}

.btn-default.btn2{ display: inline-block; color: #898989; border: none; font-size: 13px; line-height: 20px; font-weight: 400; padding: 0px 15px 0 0; text-decoration: none; border-radius:0px; -moz-border-radius:0px; -webkit-border-radius:0px; margin-top: 0px; margin-bottom: 0px; text-transform: none; background: url(../images/arr1.png) right center no-repeat;}
.btn-default.btn2:hover{color: #1cbbb4; background: url(../images/arr1_hover.png) right center no-repeat;}

.btn-default.btn3{ display: inline-block; color: #fff; border: none; font-size: 12px; line-height: 20px; font-weight: 700; padding: 6px 20px; text-decoration: none; border-radius:0px; -moz-border-radius:0px; -webkit-border-radius:0px; margin-top: 0px; margin-bottom: 0px; text-transform: uppercase; background: #fb000d; position: relative; text-align: center; box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset; moz-box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset; -webkit-box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset; margin-top: 15px;}
.btn-default.btn3:hover{color: #fff; background: #00a99d;}

.btn-default.btn4{ display: inline-block; color: #fff; border: none; font-size: 12px; line-height: 20px; font-weight: 700; padding: 6px 20px; text-decoration: none; border-radius:0px; -moz-border-radius:0px; -webkit-border-radius:0px; margin-top: 0px; margin-bottom: 0px; text-transform: uppercase; background: #00a99d; position: relative; text-align: center; box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset; moz-box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset; -webkit-box-shadow: 2px 2px 0px 0px rgba(0, 0, 0, 0.2) inset; margin-top: 15px;}
.btn-default.btn4:hover{color: #fff; background: #fb000d;}




.btn-default.s1{ font-family: 'Raleway'; display: inline-block; color: #fff; border: 1px solid #ececec; font-size: 14px; line-height: 20px; font-weight: 400; padding: 0; text-decoration: none; border-radius:0px; -moz-border-radius:0px; -webkit-border-radius:0px; margin-top: 0px; margin-bottom: 0px; background: url(../images/type1.png) center center no-repeat #fafafa; position: relative;  letter-spacing: 0; width: 42px; height: 42px;
transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -webkit-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out;
}
.btn-default.s1:hover{background: url(../images/type1_hover.png) center center no-repeat #1cbbb4; border-color: #1cbbb4;}

.btn-default.s2{ font-family: 'Raleway'; display: inline-block; color: #fff; border: 1px solid #ececec; font-size: 14px; line-height: 20px; font-weight: 400; padding: 0; text-decoration: none; border-radius:0px; -moz-border-radius:0px; -webkit-border-radius:0px; margin-top: 0px; margin-bottom: 0px; background: url(../images/type2.png) center center no-repeat #fafafa; position: relative;  letter-spacing: 0; width: 42px; height: 42px;
transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -webkit-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out;
}
.btn-default.s2:hover{background: url(../images/type3_hover.png) center center no-repeat #1cbbb4; border-color: #1cbbb4;}

.btn-default.s3{ font-family: 'Raleway'; display: inline-block; color: #fff; border: 1px solid #ececec; font-size: 14px; line-height: 20px; font-weight: 400; padding: 0; text-decoration: none; border-radius:0px; -moz-border-radius:0px; -webkit-border-radius:0px; margin-top: 0px; margin-bottom: 0px; background: url(../images/type3.png) center center no-repeat #fafafa; position: relative;  letter-spacing: 0; width: 42px; height: 42px;
transition: all 0.3s ease-out; -moz-transition: all 0.3s ease-out; -webkit-transition: all 0.3s ease-out; -o-transition: all 0.3s ease-out;
}
.btn-default.s3:hover{background: url(../images/type3_hover.png) center center no-repeat #1cbbb4; border-color: #1cbbb4;}



