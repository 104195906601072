#slider_wrapper {
    position: relative;
    padding-top: 0px;
    padding-bottom: 0px;
}

#slider_inner {}

#slider {
    position: relative;
    padding-top: 0px;
    padding-bottom: 0px;
}

.slider_pagination {
    padding-top: 160px;
}

.slider_pagination a {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin-right: 5px;
    background: url(../../assets/images/pagination.png) center center no-repeat;
    position: relative;
    overflow: hidden;
    text-indent: -9999px;
}

.slider_pagination a:hover,
.slider_pagination a.selected {
    background: url(../../assets/images/pagination_hover.png) center center no-repeat;
}


#slider .carousel-box {
    position: relative;
}

#slider .carousel-box .inner {
    position: relative;
    overflow: hidden;
    max-width: none;
    margin: 0;
}

#slider .carousel.main {
    position: relative;
    overflow: hidden;
    margin: 0;
    padding: 0;
    margin-right: -30px;
}

#slider .caroufredsel_wrapper {
    padding: 0;
    margin: 0;
}

#slider .caroufredsel_wrapper ul {
    padding: 0;
    margin: 0;
}

#slider .carousel-box ul li {
    float: left;
    display: inline-block;
    padding: 0px 0px 0px 0px;
    position: relative;
    width: 100%;
    overflow: hidden;
    padding-right: 30px
}

.slider {}

.slider .slider_inner {
    line-height: 1.2;
    color: #fff;
}

.slider .slider_inner span {
    background: #00a99d;
    background: rgba(0, 169, 157, 0.56);
    padding: 5px 15px;
    display: inline-block;
}

.slider .slider_inner .txt1 {
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 5px;
}

.slider .slider_inner .txt2 {
    font-weight: 800;
    font-size: 45px;
    margin-bottom: 5px;
}

.slider .slider_inner .txt3 {
    font-weight: 400;
    font-size: 18px;
}

#slider_wrapper {
    padding-bottom: 180px;
    padding-top: 270px;
    background: url(../../assets/images/wallpaper.jpg) center center no-repeat;
    background-size: cover;
}

@media (min-width: 2560px) {

    #slider_wrapper {
        background: url(../../assets/images/slider_large.jpg) top center no-repeat;
        background-size: cover;
        padding-bottom: 280px;
        padding-top: 370px;
    }

    #home {
        height: 1005px;
    }

}

@media (max-width: 991px) {


    #slider_wrapper {
        padding-bottom: 50px;
        padding-top: 170px;
    }

    #home {
        height: 551px;
    }
}

@media (max-width: 767px) {


    #slider_wrapper {
        padding-bottom: 10px;
        padding-top: 100px;
    }

    #home {
        height: 362px;
    }
}