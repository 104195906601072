.top_banner {
  background: #fafafa;
  border-top: 3px solid #1cbbb4;
  padding-top: 13px;
  padding-bottom: 13px;

  .email {
    float: left;
    border-right: 1px solid #ebebeb;
    margin-right: 20px;
    padding-right: 20px;

    i {
      margin-right: 8px;
      font-size: 16px;
      position: relative;
      top: 1px;
    }
  }

  .email a {
    display: block;
    font-size: 13px;
    color: #acacac;
    text-decoration: none;
    line-height: 20px;
  }

  .phone {
    float: left;

    i {
      margin-right: 8px;
      font-size: 16px;
      position: relative;
      top: 1px;
    }
  }

  .social_wrapper {
    float: right;

    .social {
      display: block;
      margin: 0;
      padding: 0;
      list-style: none;
    }

    .social li {
      display: inline-block;
      margin: 0 10px;
      float: left;
    }

    .social li a {
      display: block;
      text-decoration: none;
      font-size: 18px;
      color: #ebebeb;
      text-align: center;
      line-height: 20px;
      transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
    }

    .social li a:hover {
      color: #1cbbb4;
    }

  }



  .lang {
    float: right;
    border-right: 1px solid #ebebeb;
    border-left: 1px solid #ebebeb;
    width: 150px;
    margin-right: 20px;

    .dropdown-toggle {
      border: none;
      padding: 0;
      background: none !important;
      outline: none;
      display: block;
      width: 100%;
      padding-left: 30px;
      text-align: left;
      font-size: 13px;
      line-height: 20px;
      color: #acacac;
    }

    .dropdown-toggle:focus,
    .dropdown-toggle:active {
      background: none;
      outline: none;
      box-shadow: none;
    }

    .dropdown-toggle:before {
      content: '';
      display: inline-block;
      width: 18px;
      height: 12px;
      background: url(../../assets/images/flag_en.png) 0 0 no-repeat;
      vertical-align: top;
      margin-top: 3px;
      margin-right: 5px;
    }

    .dropdown-toggle .caret {
      display: inline-block;
      width: 10px;
      height: 6px;
      border: none;
      background: url(../../assets/images/arr0.png);
      margin-left: 10px;
    }


    .dropdown-menu {
      min-width: 100%;
      margin: 0;
      padding: 0;
      left: -1px;
      right: -1px;
      margin-top: 13px;
      border-radius: 0;
      border: 1px solid #ebebeb;
      box-shadow: none;
    }

    .dropdown-menu a {
      display: block;
      font-size: 13px;
      line-height: 20px;
      padding: 4px 30px;
      color: #acacac;
      text-decoration: none;
    }

    .dropdown-menu a:hover {
      background: #3dd2cb;
      color: #fff;
    }

    .dropdown-menu a:before {
      content: '';
      display: inline-block;
      width: 18px;
      height: 12px;
      background: url(../../assets/images/flag_en.png) 0 0 no-repeat;
      vertical-align: top;
      margin-top: 3px;
      margin-right: 5px;
    }

    .dropdown-menu a.ge:before {
      background: url(../../assets/images/flag_ge.png) 0 0 no-repeat;
    }

    .dropdown-menu a.ru:before {
      background: url(../../assets/images/flag_ru.png) 0 0 no-repeat;
    }
  }
}

@media (max-width: 767px) {
  .top_banner {
    display: none;
  }
}