.offer {}

.offer .offer_inner {
  //  padding-right: 30px;
}

figure {
  margin: 0;
}

.offer {

}

.offer .offer_inner figure {
    position: relative;
}

.offer .offer_inner figure img {
    position: relative;
    width: 100%;
}

.offer .offer_inner figure .over {
    background: #1cbbb4;
    background: rgba(28, 187, 180, 0.8);
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 10px;
    width: 100%;
    color: #fff;
    opacity: 0;
    filter: alpha(opacity=0);
    transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}

.offer .offer_inner:hover figure .over {
    opacity: 1;
    filter: alpha(opacity=100);
}

.offer .offer_inner figure .over .title_over {
    font-weight: 700;
    font-size: 21px;
}

.offer .offer_inner figure .over .title_over span {
    color: #fef697;
    font-size: 14px;
    padding-left: 5px;
    font-style: italic;
    font-weight: 400;
}

.offer .offer_inner figure .over .description_over {}

.offer .offer_inner .caption {
    padding: 25px;
    border-bottom: 3px solid transparent;
    background: #fafafa;
}

.offer .offer_inner:hover .caption {
    border-color: #1cbbb4;
}

.offer .offer_inner .title {
    font-weight: 500;
    padding-bottom: 5px;
    font-size: 12px;
    color: #fb000d;
}

.offer .offer_inner .title span {
    padding-right: 5px;
    font-size: 21px;
    color: #636363;
}

.offer .offer_inner:hover .title span {
    color: #1cbbb4;
}

.offer .offer_inner .description {
    color: #959595;
    padding-bottom: 20px;
}

.offer .offer_inner .tool_bar {}

.offer .offer_inner .tool_bar .left_side {
    float: left;
    padding-top: 5px;
}

.offer .offer_inner .tool_bar .right_side {
    float: right;
}

.offer .offer_inner .tool_bar .nums {
    display: inline-block;
    font-size: 12px;
    color: #c2c2c2;
}
