.footer_wrapper {
    background: #252525;
    border-top: 4px solid #1cbbb4;
    padding-top: 35px;
    padding-bottom: 45px;
    color: #959595;

    .title {
        font-size: 24px;
        color: #898989;
        font-weight: 700;
        padding-top: 40px;
        padding-bottom: 20px;
    }

    .logo_wrapper {
        padding-bottom: 10px;
    }

    .social_wrapper {
        padding-top: 10px;

        .social {
            display: block;
            margin: 0;
            padding: 0;
            list-style: none;

            li {
                display: inline-block;
                margin: 0 4px 4px 0;
                float: left;
            }

            li a {
                display: block;
                width: 39px;
                height: 39px;
                text-decoration: none;
                background-position: left top;
                background-repeat: no-repeat;
                transition: all 0.3s ease-out;
                -moz-transition: all 0.3s ease-out;
                -webkit-transition: all 0.3s ease-out;
                -o-transition: all 0.3s ease-out;
            }

            li a:hover {
                background-position: bottom;
            }

            li.nav1 a {
                background-image: url(../../assets/images/social_ic1.png);
            }

            li.nav2 a {
                background-image: url(../../assets/images/social_ic2.png);
            }

            li.nav3 a {
                background-image: url(../../assets/images/social_ic3.png);
            }

            li.nav4 a {
                background-image: url(../../assets/images/social_ic4.png);
            }

            li.nav5 a {
                background-image: url(../../assets/images/social_ic5.png);
            }

            li.nav6 a {
                background-image: url(../../assets/images/social_ic6.png);
            }

        }
    }


    .twits {
        .twit {
            color: #959595;
            border-top: 1px solid #363636;
            padding: 8px 0;

            &:first-child {
                border-top: none;
            }

            a {
                color: #6dcff6;
                text-decoration: none;
            }

            .date {
                color: #464646;
                font-size: 12px;
            }
        }
    }

    .newsletter_block {
        .txt1 {
            font-size: 12px;
            padding-bottom: 5px;
        }

        .newsletter-wrapper {
            .newsletter {
                input {
                    width: 100%;
                    height: auto;
                    font-size: 14px;
                    line-height: 20px;
                    color: #555555;
                    background: url(../../assets/images/newsletter.png) right center no-repeat #101010;
                    border: 1px solid #343434;
                    margin: 0;
                    padding: 10px 50px 10px 10px;
                    border-radius: 0px;
                    -moz-border-radius: 0px;
                    -webkit-border-radius: 0px;
                    -moz-box-shadow: none;
                    -webkit-box-shadow: none;
                    box-shadow: none;

                    &:focus {
                        color: #fff;
                        background-color: #000;
                        border-color: #707070;
                        -moz-box-shadow: none;
                        -webkit-box-shadow: none;
                        box-shadow: none;
                        outline: none;
                    }
                }
            }
        }
    }

    .phone {
        font-size: 30px;
        color: #1cbbb4;
        padding-top: 5px;
    }

    .support {
        color: #555555;

        a {
            color: #555555;
        }
    }
}