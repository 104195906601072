#toTop {
    display: none;
    text-decoration: none;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1000;
    overflow: hidden;
    width: 50px;
    height: 50px;
    border: none;
    text-indent: 100%;
    background: url(../../assets/images/totop.gif) no-repeat left top;
    transition: background 0.4s ease;

    &:active,
    &:focus {
        outline: none;
    }
    &:hover {
        background: url(../../assets/images/totop.gif) no-repeat left bottom;
        width: 50px;
        height: 50px;
        display: block;
        overflow: hidden;
        float: left;
    //    opacity: 0;
        -moz-opacity: 0;
      //  filter: opacity(0);
    }
}

