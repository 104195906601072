/*.bot2_wrapper .left_side {
    float: none;
}

.bot2_wrapper .right_side {
    float: none;
}*/

.copyright-wrapper {
    background: #00a99d;
    padding-top: 29px;
    padding-bottom: 29px;
    color: #fff;
    font-size: 13px;

    .container {
        height: 20px;
    }

    a {
        color: #fff;
        text-decoration: none;
    }
    
    a:hover {
        text-decoration: underline;
    }
    
    span {
        padding: 0 5px;
    }
    
    .left-side {
        float: left;
    }
    
    .right-side {
        float: right;
    }
}

