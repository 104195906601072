.menu-bar {
  position: relative;
  background: #fff;

  .menu-bar-content {
    position: relative;

    header {
      position: absolute;
      left: 0;
      top: 10px;
      z-index: 31;
    }
  }

  .menu-bar-navbar {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
    position: relative;
    z-index: 30;
    min-height: 0;
    float: right;
    display: block;
    background: none;
    line-height: 1px;

    .navbar-toggler {
      float: right;
      background: #1cbbb4;
      border: none;
      box-shadow: none;
      moz-box-shadow: none;
      -webkit-box-shadow: none;
      margin-top: 41px;
      margin-bottom: 41px;
      margin-left: 15px;
    }

    .navbar-toggler:hover,
    .navbar-toggler:focus {
      background: #076f68;
    }

    .navbar-toggler .icon-bar {
      background: #fff;
    }

    .nav {
      position: relative;
      margin: 0;
      padding: 0;
      display: block;
      float: none;
      padding-right: 0px;
      background: none;
    }

/*    .nav > li {
      position: relative;
      display: block;
      float: left;
      white-space: nowrap;
      padding-left: 0px;
      background: none;
      margin-left: 50px;
    }

    .nav > li:first-child {
      margin-left: 0;
    }

    .nav > li > a {
      display: block;
      text-decoration: none;
      background: none;
      font-family: 'Raleway';
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #8c8c8c;
      text-shadow: none;
      padding: 45px 0px 45px 0;
      border: none;
      margin: 0;
      border-radius: 0px;
      -moz-border-radius: 0px;
      -webkit-border-radius: 0px;
      text-align: left;
      text-transform: none;
      vertical-align: middle;
      border-bottom: 3px solid transparent;
      transition: color 0.3s ease-out;
      -moz-transition: color 0.3s ease-out;
      -webkit-transition: color 0.3s ease-out;
      -o-transition: color 0.3s ease-out;
    }

    .nav > li > a em {
      display: inline-block;
      width: 8px;
      height: 5px;
      background: url(../../assets/images/caret.png);
      margin-left: 5px;
    }*/

    .nav > li > a:hover,
    .nav > li > a:focus,
    .nav > li.sfHover > a,
    .nav > li.sfHover > a:hover {
      color: #1cbbb4;
      text-decoration: none;
      background: none;
      box-shadow: none;
      moz-box-shadow: none;
      -webkit-box-shadow: none;
      border-color: #1cbbb4;
    }

    .nav > li > a.active,
    .nav > li > a.active:hover,
    .nav > li > a.active:focus {
      color: #1cbbb4;
      text-decoration: none;
      background: none;
      box-shadow: none;
      moz-box-shadow: none;
      -webkit-box-shadow: none;
      border-color: #1cbbb4;
    }


    .navbar-collapse_ {
      padding-left: 0;
      padding-right: 0;
      border: none;
    }


  }


}


// Submenu

/*

.sub-menu ul {
    position: absolute;
    display: none;
    left: -25px;
    top: 75px;
    list-style: none;
    zoom: 1;
    z-index: 10;
    margin: 0;
    padding: 15px 25px;
    background: #fff;
    text-align: left;
    margin-top: -6px;
    min-width: 180px;
    box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
    moz-box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
    -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
}

.sub-menu li {
    margin: 0;
    position: relative;
    zoom: 1;
    display: block;
    border-top: 1px solid #ebebeb;
}

.sub-menu li:first-child {
    border: none;
}

.sub-menu li a {
    display: block;
    position: relative;
    font-family: 'Raleway';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #8c8c8c;
    text-decoration: none;
    padding: 10px 0px;
    background: none;
    transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
}

.sub-menu li a:hover {
    text-decoration: none;
    color: #00a99d;
}

@media(min-width:768px) {
    .sub-menu li ul {
        position: absolute;
        display: none;
        left: 158px;
        top: 0px;
        list-style: none;
        zoom: 1;
        z-index: 10;
        margin: 0;
        padding: 6px 25px;
        background: #fff;
        text-align: left;
        margin-top: -6px;
        min-width: 180px;
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
        moz-box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: 0 0 25px rgba(0, 0, 0, 0.08);
    }

    .sub-menu li ul li {
        margin: 0;
        position: relative;
        zoom: 1;
        display: block;
        border-top: 1px solid #ebebeb;
    }

    .sub-menu li ul li ul:first-child {
        border: none;
    }

    .sub-menu li ul li a {
        display: block;
        position: relative;
        font-family: 'Raleway';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #8c8c8c;
        text-decoration: none;
        padding: 10px 0px;
        background: none;
        transition: all 0.3s ease-out;
        -moz-transition: all 0.3s ease-out;
        -webkit-transition: all 0.3s ease-out;
        -o-transition: all 0.3s ease-out;
    }

    .sub-menu li ul li a:hover {
        text-decoration: none;
        color: #00a99d;
    }
}



.sub-menu-1>ul {}

.sub-menu-2>ul {
    left: 100%;
    top: 0;
    margin-left: 0px;
    background: #002745;
}
    */

.navbar_ { margin: 0; padding: 0; background: none; border:none; position: relative;z-index: 30; min-height: 0; float: right; display: block; background: none; line-height: 1px;}
.navbar_ .navbar-toggle {float: right; background: #1cbbb4; border: none;box-shadow: none; moz-box-shadow: none; -webkit-box-shadow: none; margin-top: 41px; margin-bottom: 41px; margin-left: 15px;}
.navbar_ .navbar-toggle:hover, .navbar_ .navbar-toggle:focus{background: #076f68;}
.navbar_ .navbar-toggle .icon-bar{background: #fff;}
.navbar-collapse_{padding-left: 0; padding-right: 0; border: none;}
.navbar_ .nav{position: relative; margin: 0; padding: 0; display: block; float: none; padding-right: 0px; background: none;}
.navbar_ .nav > li{position: relative; display: block; float: left; white-space: nowrap; padding-left: 0px; background: none; margin-left: 50px;}
.navbar_ .nav > li:first-child{margin-left: 0;}
.navbar_ .nav > li > a{display: block; text-decoration: none; background: none; font-family: 'Raleway'; font-weight: 500; font-size: 14px; line-height: 20px; color: #8c8c8c; text-shadow:none; padding: 45px 0px 45px 0; border: none; margin: 0; border-radius:0px; -moz-border-radius:0px; -webkit-border-radius:0px; text-align: left; text-transform: none; vertical-align: middle; border-bottom: 3px solid transparent;
  transition: color 0.3s ease-out; -moz-transition: color 0.3s ease-out; -webkit-transition: color 0.3s ease-out; -o-transition: color 0.3s ease-out;
}
.navbar_ .nav > li > a em{display: inline-block; width: 8px; height: 5px; background: url(../../assets/images/caret.png); margin-left: 5px;}

.navbar_ .nav > li > a:hover, .navbar_ .nav > li > a:focus, .navbar_ .nav > li.sfHover > a, .navbar_ .nav > li.sfHover > a:hover{color: #1cbbb4; text-decoration: none; background: none; box-shadow: none; moz-box-shadow: none; -webkit-box-shadow: none; border-color: #1cbbb4; }
.navbar_ .nav > li.active > a, .navbar_ .nav > li.active > a:hover, .navbar_ .nav > li.active > a:focus{color: #1cbbb4; text-decoration: none; background: none; box-shadow: none; moz-box-shadow: none; -webkit-box-shadow: none; border-color: #1cbbb4;}


@media (max-width: 767px) {
  .navbar-collapse_ {
    clear: both;
  }

  .navbar_ {
    float: none;
    margin-bottom: 0;
    margin-left: -15px;
    margin-right: -15px;
    display: block;
    height: auto;
    padding-right: 0;
    background: none;
  }
  .navbar_ .nav {
    display: block;
    background: #fff;
    padding-right: 0;
  }
  .navbar_ .nav > li {
    float: none;
    background: none;
    padding-left: 0;
    border-top: none;
    margin-left: 0;
  }
  .navbar_ .nav > li:first-child {
    border-top: none;
  }
  .navbar_ .nav > li > a {
    margin-bottom: 0;
    margin-top: 0;
    padding: 5px 0;
    text-align: center;
    font-size: 14px;
  }
  .navbar_ .nav > li.active > a, .navbar_ .nav > li.active > a:hover, .navbar_ .nav > li.active > a:focus, .navbar_ .nav > li > a:hover, .navbar_ .nav > li > a:focus, .navbar_ .nav > li.sfHover > a, .navbar_ .nav > li.sfHover > a:hover {
    background: #fff;
  }

}